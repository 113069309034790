<template>
  <section>
    <folha v-for="(elementos, indexFolha) in tamanhoElemento" :key="indexFolha">
      <br /><br />
      <h3 v-if="via == '2' && indexFolha === 0">2ª VIA DO LAUDO</h3>
      <br /><br />
      <div v-if="indexFolha === 0">
        <div class="header maiuscula" style="">
          <h3><strong>NOME:</strong> {{ paciente.nome }}</h3>
          <h3><strong>DATA DO EXAME:</strong> {{ consulta.data }}</h3>
          <!-- <h3><strong>CONVÊNIO:</strong> {{ convenio.nome }}</h3> -->
          <br />
        </div>

        <br /><br />
        <h3>ULTRASSONOGRAFIA MAMA</h3>
        <h3>AXILA</h3>
        <br /><br />
      </div>

      <div v-for="(elemento, indexElemento) in elementos" :key="indexElemento">
        <h4 v-if="titulos.includes(elemento)">
          <p></p>
          {{ elemento }}
          
        </h4>
        <div v-else>
          {{ elemento }}
        </div>
      </div>
      <br />
      <br />
      <footer v-if="indexFolha + 1 == tamanhoElemento.length">
        <span
          ><strong
            >Dra
            {{
              medico.nome == 'Adriana Melo (curso)'
                ? 'Adriana Melo'
                : medico.nome
            }}</strong
          > </span
        ><br />
        <span
          ><strong>CRM/PB- {{ medico.crm }} </strong></span
        >
      </footer>
    </folha>
  </section>
</template>

<script>
import ImprecoesMixins from '@mixins/ImprecoesMixins';
export default {
  mixins: [ImprecoesMixins],
  mounted() {
    let folha = [];
    if (this.$route.query.via) this.via = this.$route.query.via;

    if (this.laudo.transdutorLinear)
      folha.push(
        `Exame realizado com transdutor linear de alta resolução multifrequencial ( ${this.laudo.transdutorLinear} a ${this.laudo.transdutorLinear2} MHz). `
      );
    if (this.laudo.indicacaoExame)
      folha.push(`Indicação do exame ${this.laudo.indicacaoExame}. `);

    if (folha.length > 0) folha.splice(0, 0, '');
    this.inicio = [...folha];
    folha = [];

    if (this.laudo.mamaDireitaPrevia0)
      folha.push(`Mastectomia total prévia sem evidências de nódulos.`);
    if (this.laudo.mamaDireitaAreolo1)
      folha.push(` Pele e complexo aréolo papilar íntegros.`);
    if (this.laudo.mamaDireitaFibroglandular1)
      folha.push(`Parênquima mamário com predominância do tecido fibroglandular sobre o
        tecido.`);
    if (this.laudo.mamaDireitaAdiposo1)
      folha.push(
        `Parênquima mamário com predominância do tecido adiposo sobre o tecido fibroglandular.`
      );
    if (this.laudo.mamaDireitaImagens1)
      folha.push(`Ausência de imagens nodulares sólidas ou císticas.`);
    if (this.laudo.mamaDireitaSilicone1)
      folha.push(`Parênquima Prótese de silicone com forma habitual, contornos regulares, sem sinais
        de descontinuidade e contraturas. com predominância do tecido adiposo sobre o tecido fibroglandular.`);
    if (this.laudo.mamaDireitaMorfoestruturais1)
      folha.push(`Alterações morfoestruturais do tecido fibroglandular, secundário a
        procedimento cirúrgico.`);

    if (this.laudo.mamaDireitaSemFluxo1)
      folha.push(`Imagens alongadas, anecóicas, sem fluxo ao estudo Doppler, sugestivas de
        ectasias ductais na região retroareolar.`);
    if (this.laudo.checkMamaDireita)
      folha.push(`Formação cística, de limites precisos, conteúdo anecóico, paredes finas,
        reforço acústico, localizado no ${this.laudo.checkMamaDireita22} (_ h),
        medindo ${this.laudo.checkMamaDireita23} cm.`);
    if (this.laudo.checkMamaDireitaReforcoAnecoico)
      folha.push(`Formações císticas, de limites precisos, conteúdo anecóico, paredes
        finas, reforço acústico, com o maior medindo
        ${this.laudo.checkMamaDireitaReforcoAnecoico22} cm.`);
    if (this.laudo.checkMamaDireitaMicrocistos)
      folha.push(`Microcistos (diâmetros até 3.0mm), agrupados, com septações finas entre
        eles, reforço acústico, localizados em ${this.laudo.checkMamaDireitaMicrocistos23}
        cm.`);
    if (this.laudo.checkMamaDireitaFormacoes)
      folha.push(`Formações nodulares, hipoecóicas, circunscritas, maior eixo paralelo à
        pele, assim distribuídas:${this.laudo.checkMamaDireitaFormacoes32}
        cm.`);
    if (this.laudo.checkMamaDireitaContornoIrregular)
      folha.push(`Formação nodular, hipoecóica, contornos irregulares, maior eixo
        perpendicular à pele,
        projetando sombra acústica, localizada em${this.laudo.checkMamaDireitaContornoIrregular1}
        (__h), medindo ${this.laudo.checkMamaDireitaContornoIrregular2} x
        ${this.laudo.checkMamaDireitaContornoIrregular3} cm, distando

        ${this.laudo.checkMamaDireitaContornoIrregular4} cm da aréola e
        ${this.laudo.checkMamaDireitaContornoIrregular5} cm da pele.`);
    if (this.laudo.comentariosAdcionaisMeio1)
      folha.push(
        `Comentários Adcionais: ${this.laudo.comentariosAdcionaisMeio1}`
      );
    //termina mama direira
    if (this.laudo.peleSubcutaneo)
      folha.push(`Pele e subcutâneo ${
        this.laudo.peleSubcutaneo != 'outros' ? this.laudo.peleSubcutaneo : ''
      }${
        [
          'presença de edema ',
          'presença de retração ',
          'presença de cisto ',
          'outros',
        ].includes(this.laudo.peleSubcutaneo)
          ? this.laudo.peleSubcutaneo2
            ? ' ' + this.laudo.peleSubcutaneo2
            : ''
          : ''
      }.

      `);
    if (this.laudo.areolaPapila)
      folha.push(
        `Aréola e papila  ${
          this.laudo.areolaPapila != 'outros' ? this.laudo.areolaPapila : ''
        }${
          [
            'presença de edema',
            'presença de retração',
            'presença de cisto',
            'outros',
          ].includes(this.laudo.areolaPapila)
            ? this.laudo.areolaPapila2
              ? ' ' + this.laudo.areolaPapila2
              : ''
            : ''
        }. `
      );
    if (this.laudo.composicaoMamaria)
      folha.push(`Composição mamária  ${this.laudo.composicaoMamaria}. `);
    if (this.laudo.mamaEsqPrevia1)
      folha.push(
        `Ausência de imagens nodulares de etiologia sólida ou cística.`
      );
    if (this.laudo.presenca)
      folha.push(
        `Presença de ${this.numText[this.laudo.presenca]} ${
          this.laudo.presenca > 1
            ? ' nódulos, assim distribuídos:'
            : ' nódulo, assim distribuído:'
        }`
      );
    if (!!this.laudo.listaPresencas) {
      if (this.laudo.listaPresencas.length > 0)
        for (var i = 0; i < this.laudo.listaPresencas.length; i++) {
          folha.push(` Nódulo ${i + 1} - ${
            this.laudo.listaPresencas[i].presenca.opc1
              ? this.laudo.listaPresencas[i].presenca.opc1
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.opc2
              ? this.laudo.listaPresencas[i].presenca.opc2
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.opc3
              ? this.laudo.listaPresencas[i].presenca.opc3
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.opc4
              ? this.laudo.listaPresencas[i].presenca.opc4
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.opc5
              ? this.laudo.listaPresencas[i].presenca.opc5
              : ''
          } medindo ${
            this.laudo.listaPresencas[i].presenca.medida1Esq
              ? this.laudo.listaPresencas[i].presenca.medida1Esq
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.medida2Esq
              ? ' x ' + this.laudo.listaPresencas[i].presenca.medida2Esq
              : ''
          }  ${
            this.laudo.listaPresencas[i].presenca.medida3Esq
              ? ' x ' + this.laudo.listaPresencas[i].presenca.medida3Esq
              : ' cm, '
          }${
            this.laudo.listaPresencas[i].presenca.medidaCmPeleEsq
              ? ' distando ' +
                this.laudo.listaPresencas[i].presenca.medidaCmPeleEsq +
                ' cm da pele e '
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.medidaCmAreolaEsq
              ? this.laudo.listaPresencas[i].presenca.medidaCmAreolaEsq +
                ' cm da aréola, '
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.locPeleAreoEsq
              ? ' localizado ' +
                this.laudo.listaPresencas[i].presenca.locPeleAreoEsq
              : ''
          }${
            this.laudo.listaPresencas[i].presenca.horaAreolaEsq
              ? ' (' +
                this.laudo.listaPresencas[i].presenca.horaAreolaEsq +
                (this.laudo.listaPresencas[i].presenca.horaAreolaEsq > 1
                  ? ' horas)'
                  : ' hora)')
              : ''
          }.

`);
        }
    }

    //-----

    if (this.laudo.medida1)
      folha.push(`medindo  ${this.laudo.medida1} x ${this.laudo.medida2} x ${
        this.laudo.medida3
      } cm, distando ${this.laudo.medidaCmPele}
      cm da pele e ${this.laudo.medidaCmAreola}  cm da aréola, localizado ${
        this.laudo.locPeleAreo
      } ${this.laudo.horaAreola > 1 ? 'as ' : 'a'}
      ${this.laudo.horaAreola} ${
        this.laudo.horaAreola > 1 ? ' horas ' : ' hora '
      }`);
    if (this.laudo.presImgcm)
      folha.push(
        `Presença de imagem anecóica medindo ${
          this.laudo.presImgcm
        } cm, localizada ${this.laudo.presImgOp}${
          this.laudo.presImgOp == 'com área sólida medindo '
            ? this.laudo.presImgOp + ' cm.'
            : '.'
        }`
      );
    if (this.laudo.CongCistoCm)
      folha.push(
        `Conglomerado de cistos medindo ${this.laudo.CongCistoCm} cm, localizado  ${this.laudo.CongCistoOpc}.`
      );
    if (this.laudo.mutlImgcm)
      folha.push(
        `Múltiplas imagens anecóicas, com a maior medindo  ${this.laudo.mutlImgcm} cm, localizada  ${this.laudo.mutlImgOpc}.`
      );

    if (this.laudo.temProtese) {
      folha.push(`Prótese direita`);
      if (this.laudo.textura) folha.push(`Textura  ${this.laudo.textura}.`);
      if (this.laudo.textura)
        folha.push(
          `Integridade da superfície  ${this.laudo.integridadeDaSuper}.`
        );
      if (this.laudo.massaInterna)
        folha.push(
          `Massa interna ${this.laudo.massaInterna}${
            this.laudo.massaInterna == 'sim, medindo'
              ? ' ' + this.laudo.massaInternaCm
              : ''
          }.`
        );
      if (this.laudo.textura) folha.push(`Rotação ${this.laudo.rotacao}.`);
      if (this.laudo.comentariosAdicionais)
        folha.push(`${this.laudo.comentariosAdicionais}.`);
    }

    if (this.laudo.comentariosAdcionais1)
      folha.push(`${this.laudo.comentariosAdcionais1}`);
    if (folha.length > 0) folha.splice(0, 0, 'Mama Direita');
    this.mamaDireita = [...folha];
    folha = [];
    //--------------------------------------
    //termina mama direita

    if (this.laudo.mamaEsquerdaPrevia2)
      folha.push(`Mastectomia total prévia sem evidências de nódulos.`);
    if (this.laudo.mamaEsquerdaAreolo1)
      folha.push(`Pele e complexo aréolo papilar íntegros.`);
    if (this.laudo.mamaEsquerdaFibroglandular1)
      folha.push(`Parênquima mamário com predominância do tecido fibroglandular sobre o
        tecido.`);
    if (this.laudo.mamaEsquerdaAdiposo1)
      folha.push(`Parênquima mamário com predominância do tecido adiposo sobre o tecido
        fibroglandular.`);
    if (this.laudo.mamaEsquerdaImagens1)
      folha.push(`Ausência de imagens nodulares sólidas ou císticas.`);
    if (this.laudo.mamaEsquerdaSilicone1)
      folha.push(
        `Prótese de silicone com forma habitual, contornos regulares, sem sinais de descontinuidade e contraturas.`
      );
    if (this.laudo.mamaEsquerdaMorfoestruturais1)
      folha.push(` Alterações morfoestruturais do tecido fibroglandular, secundário a
        procedimento cirúrgico.`);
    if (this.laudo.mamaEsquerdaSemFluxo1)
      folha.push(`Imagens alongadas, anecóicas, sem fluxo ao estudo Doppler, sugestivas de
        ectasias ductais na região retroareolar.`);
    if (this.laudo.checkMamaEsquerda)
      folha.push(`Formação cística, de limites precisos, conteúdo anecóico, paredes finas,
        reforçoacústico, localizado no ${this.laudo.checkMamaEsquerda22} (_ h),
        medindo ${this.laudo.checkMamaEsquerda23} cm.`);
    if (this.laudo.checkMamaEsquerdaReforcoAnecoico)
      folha.push(`Formações císticas, de limites precisos, conteúdo anecóico, paredes
        finas, reforço acústico, com o maior medindo
        ${this.laudo.checkMamaEsquerdaReforcoAnecoico22} cm.`);
    if (this.laudo.checkMamaEsquerdaMicrocistos)
      folha.push(`Microcistos (diâmetros até 3.0mm), agrupados, com septações finas entre
        eles,

        reforço acústico, localizados em${this.laudo.checkMamaEsquerdaMicrocistos23}
        cm.`);
    if (this.laudo.checkMamaEsquerdaFormacoes)
      folha.push(`Formações nodulares, hipoecóicas, circunscritas, maior eixo paralelo à
        pele, assim  distribuídas: ${this.laudo.checkMamaEsquerdaFormacoes32}
        cm.`);
    if (this.laudo.checkMamaEsquerdaContornoIrregular)
      folha.push(`Formação nodular, hipoecóica, contornos irregulares, maior eixo
        perpendicular à pele, projetando sombra acústica, localizada em ${this.laudo.checkMamaEsquerdaContornoIrregular1}(__h), medindo ${this.laudo.checkMamaEsquerdaContornoIrregular2}} x
        ${this.laudo.checkMamaEsquerdaContornoIrregular3} cm, distando
        ${this.laudo.checkMamaEsquerdaContornoIrregular4} cm da aréola e
        ${this.laudo.checkMamaEsquerdaContornoIrregular5} cm da pele.`);
    if (this.laudo.comentariosAdcionaisMeio2)
      folha.push(`${this.laudo.comentariosAdcionaisMeio2}`);

    if (this.laudo.comentariosAdcionais001)
      folha.push(`${this.laudo.comentariosAdcionais001}`);
    if (this.laudo.peleSubcutaneoEsq)
      folha.push(`Pele e subcutâneo ${
        this.laudo.peleSubcutaneoEsq != 'outros'
          ? this.laudo.peleSubcutaneoEsq
          : ''
      }${
        [
          'presença de edema ',
          'presença de retração ',
          'presença de cisto ',
          'outros',
        ].includes(this.laudo.peleSubcutaneoEsq)
          ? this.laudo.peleSubcutaneo2Esq
            ? ' ' + this.laudo.peleSubcutaneo2Esq
            : ''
          : ''
      }.

      `);
    if (this.laudo.areolaPapilaEsq)
      folha.push(
        `Aréola e papila  ${
          this.laudo.areolaPapilaEsq != 'outros'
            ? this.laudo.areolaPapilaEsq
            : ''
        }${
          [
            'presença de edema',
            'presença de retração',
            'presença de cisto',
            'outros',
          ].includes(this.laudo.areolaPapilaEsq)
            ? this.laudo.areolaPapila2Esq
              ? ' ' + this.laudo.areolaPapila2Esq
              : ''
            : ''
        }. `
      );
    if (this.laudo.composicaoMamariaEsq)
      folha.push(`Composição mamária  ${this.laudo.composicaoMamariaEsq}. `);
    if (this.laudo.mamaEsqPrevia1)
      folha.push(
        `Ausência de imagens nodulares de etiologia sólida ou cística.`
      );
    if (!!this.laudo.presencaEsq)
      folha.push(
        `Presença de ${this.numText[this.laudo.presencaEsq]} ${
          this.laudo.presencaEsq > 1
            ? ' nódulos, assim distribuídos:'
            : ' nódulo, assim distribuído:'
        }`
      );
    if (!!this.laudo.listaPresencasEsq) {
      if (this.laudo.listaPresencasEsq.length > 0)
        for (var i = 0; i < this.laudo.listaPresencasEsq.length; i++) {
          folha.push(` Nódulo ${i + 1} - ${
            this.laudo.listaPresencasEsq[i].presenca.opc1
              ? this.laudo.listaPresencasEsq[i].presenca.opc1
              : ''
          }${
            this.laudo.listaPresencasEsq[i].presenca.opc2
              ? this.laudo.listaPresencasEsq[i].presenca.opc2
              : ''
          }${
            this.laudo.listaPresencasEsq[i].presenca.opc3
              ? this.laudo.listaPresencasEsq[i].presenca.opc3
              : ''
          }${
            this.laudo.listaPresencasEsq[i].presenca.opc4
              ? this.laudo.listaPresencasEsq[i].presenca.opc4
              : ''
          }${
            this.laudo.listaPresencasEsq[i].presenca.opc5
              ? this.laudo.listaPresencasEsq[i].presenca.opc5
              : ''
          }  medindo ${
            this.laudo.listaPresencasEsq[i].presenca.medida1Esq
              ? this.laudo.listaPresencasEsq[i].presenca.medida1Esq
              : ''
          }${
            this.laudo.listaPresencasEsq[i].presenca.medida2Esq
              ? ' x ' + this.laudo.listaPresencasEsq[i].presenca.medida2Esq
              : ''
          }${
            this.laudo.listaPresencasEsq[i].presenca.medida3Esq
              ? ' x ' +
                this.laudo.listaPresencasEsq[i].presenca.medida3Esq +
                ' cm, '
              : ' cm, '
          }  ${
            ' distando ' +
            this.laudo.listaPresencasEsq[i].presenca.medidaCmPeleEsq +
            ' cm da pele e '
              ? this.laudo.listaPresencasEsq[i].presenca.medidaCmPeleEsq
              : ''
          }  ${
            this.laudo.listaPresencasEsq[i].presenca.medidaCmAreolaEsq
              ? this.laudo.listaPresencasEsq[i].presenca.medidaCmAreolaEsq +
                ' cm da aréola,'
              : ''
          }   ${
            this.laudo.listaPresencasEsq[i].presenca.locPeleAreoEsq
              ? ' localizado ' +
                this.laudo.listaPresencasEsq[i].presenca.locPeleAreoEsq
              : ''
          }${
            this.laudo.listaPresencasEsq[i].presenca.horaAreolaEsq
              ? ' (' +
                this.laudo.listaPresencasEsq[i].presenca.horaAreolaEsq +
                (this.laudo.listaPresencasEsq[i].presenca.horaAreolaEsq > 1
                  ? ' horas)'
                  : ' hora)')
              : ''
          }.
`);
        }
    }

    if (this.laudo.medida1Esq)
      folha.push(`medindo  ${this.laudo.medida1Esq} x ${
        this.laudo.medida2Esq
      } x ${this.laudo.medida3Esq} cm, distando ${this.laudo.medidaCmPeleEsq}
      cm da pele e ${this.laudo.medidaCmAreolaEsq}  cm da aréola, localizado ${
        this.laudo.locPeleAreoEsq
      } ${this.laudo.horaAreolaEsq > 1 ? 'as ' : 'a'}
      ${this.laudo.horaAreolaEsq} ${
        this.laudo.horaAreolaEsq > 1 ? ' horas ' : ' hora '
      }`);
    if (this.laudo.presImgcmEsq)
      folha.push(
        `Presença de imagem anecóica medindo ${
          this.laudo.presImgcmEsq
        } cm, localizada ${this.laudo.presImgOpEsq}${
          this.laudo.presImgOpEsq == 'com área sólida medindo '
            ? this.laudo.presImgOpEsq + ' cm.'
            : '.'
        }`
      );
    if (this.laudo.CongCistoCmEsq)
      folha.push(
        `Conglomerado de cistos medindo ${this.laudo.CongCistoCmEsq} cm, localizado  ${this.laudo.CongCistoOpcEsq}.`
      );
    if (this.laudo.mutlImgcmEsq)
      folha.push(
        `Múltiplas imagens anecóicas, com a maior medindo  ${this.laudo.mutlImgcmEsq} cm, localizada  ${this.laudo.mutlImgOpcEsq}.`
      );

    if (this.laudo.temProteseEsq) {
      folha.push(`Prótese esquerda`);
      if (this.laudo.texturaEsq)
        folha.push(`Textura  ${this.laudo.texturaEsq}.`);
      if (this.laudo.texturaEsq)
        folha.push(
          `Integridade da superfície  ${this.laudo.integridadeDaSuperEsq}.`
        );
      if (this.laudo.massaInternaEsq)
        folha.push(
          `Massa interna ${this.laudo.massaInternaEsq}${
            this.laudo.massaInternaEsq == 'sim, medindo'
              ? ' ' + this.laudo.massaInternaCmEsq
              : ''
          }.`
        );
      if (this.laudo.texturaEsq)
        folha.push(`Rotação ${this.laudo.rotacaoEsq}.`);
      if (this.laudo.comentariosAdicionaisEsq)
        folha.push(`${this.laudo.comentariosAdicionaisEsq}.`);
    }

    if (folha.length > 0) folha.splice(0, 0, 'Mama Esquerda');
    this.mamaEsquerda = [...folha];
    folha = [];
    //termina mama esquerda

    if (this.laudo.axilaDireitaLivre1)
      folha.push(`Prolongamento axilar livre de linfonodomegalias suspeitas.`);
    if (this.laudo.checkAxilaDireita)
      folha.push(`Presença de ${this.laudo.checkAxilaDireita33} linfonodos de aspecto
        habitual medindo ${this.laudo.checkAxilaDireita34} cm.`);
    if (this.laudo.comentariosAdcionaisMedio22)
      folha.push(` ${this.laudo.comentariosAdcionaisMedio22}`);
    if (folha.length > 0) folha.splice(0, 0, 'Axila Direita');
    this.axilaDireita = [...folha];
    folha = [];
    //termina Axila Direita
    if (this.laudo.axilaEsquerdaLivre1)
      folha.push(` Prolongamento axilar livre de linfonodomegalias suspeitas.`);
    if (this.laudo.checkAxilaEsquerda)
      folha.push(`Presença de ${this.laudo.checkAxilaEsquerda33} linfonodos de aspecto
        habitual medindo ${this.laudo.checkAxilaEsquerda34} cm.`);
    if (this.laudo.comentariosAdcionaisMeio3)
      folha.push(`${this.laudo.comentariosAdcionaisMeio3}`);

    if (folha.length > 0) folha.splice(0, 0, 'Axila Esquerda');
    this.axilaEsquerda = [...folha];
    folha = [];
    //termina Axila Esquerda

    if (this.laudo.impressaoDiagnostica1)
      folha.push(`Ultrassonografia mamária dentro dos padrões da normalidade.`);
    if (this.laudo.impressaoDiagnostica2)
      folha.push(`Prótese de silicone de aspecto ecográfico normal..`);
    if (this.laudo.impDiag1)
      folha.push(`Ultrassonografia mamária dentro dos padrões da normalidade.`);
    if (this.laudo.impDiag2) folha.push(`Nódulos sólidos bilaterais.`);
    if (this.laudo.impDiag3) folha.push(`Nódulo sólido na mama direita.`);
    if (this.laudo.impDiag5) folha.push(`Nódulo sólido na mama esquerda.`);
    if (this.laudo.impDiag6) folha.push(`Cistos mamários.`);
    if (this.laudo.impDiag7) folha.push(`Cistos na mama direita.`);
    if (this.laudo.impDiag8) folha.push(`Cistos na mama esquerda.`);
    if (this.laudo.impDiagN1)
        folha.push(`Cisto na mama direita. \n`);
        if (this.laudo.impDiagN2)
        folha.push(`Cisto na mama esquerda. \n`);
        if (this.laudo.impDiagN3)
        folha.push(`Ectasia ductal sem conteúdo sólido.\n`);
        if (this.laudo.impDiagN4)
        folha.push(`Ectasia ductal com conteúdo sólido (papiloma?).\n`);
        if (this.laudo.impDiagN5)
        folha.push(`Linfonodo intra-mamário à direita.\n`);
        if (this.laudo.impDiagN6)
        folha.push(`Linfonodo intra-mamário à esquerda.\n`);
      if (this.laudo.impDiagN7)
      folha.push(`Mastectomia prévia à esquerda.\n`);
      if (this.laudo.impDiagN8)
        folha.push(`Mastectomia prévia à direita.\n`);
    if (this.laudo.impDiag9)
      folha.push(`Prótese de silicone de aspecto ecográfico normal.`);
      if (this.laudo.impDiag10)
      folha.push(
        `Prótese de silicone à direita de aspecto ecográfico normal.`
      );
    if (this.laudo.impDiag11)
      folha.push(
        `Prótese de silicone à esquerda de aspecto ecográfico normal.`
      );
    if (this.laudo.impDiag12)
      folha.push(`Sinais de rotura intracapsular à direita.`);
    if (this.laudo.impDiag13)
      folha.push(`Sinais de rotura intracapsular à esquerda.`);
    if (this.laudo.impDiag14)
      folha.push(`Sinais de rotura intracapsular bilateral.`);
      
      if (this.laudo.impDiagN15)
      folha.push(`Sinais de rotura extracapsular à direita.`);
      if (this.laudo.impDiagN16)
      folha.push(`Sinais de rotura extracapsular à esquerda.`);
      if (this.laudo.impDiagN17)
      folha.push(`Sinais de rotura extracapsular bilateral.`);
      if (this.laudo.impDiagN18)
      folha.push(`Rotação da prótese.`);

    if (this.laudo.comentariosAdicionaisAnaliseComp)
      folha.push(` ${this.laudo.comentariosAdicionaisAnaliseComp}`);
    // if (this.laudo.categoria1)
    //   folha.push(` Categoria ${this.laudo.categoria1} pelo sistema de padronização BI-RADS
    //     ultrassonográfico.`);
    if (this.laudo.comentariosAdicionaisAnaliseComp)
      folha.push(` ${this.laudo.comentariosAdicionaisAnaliseComp}`);

    if (folha.length > 0) folha.splice(0, 0, 'Impressão Diagnóstica');
    this.impressaoDiagnostica = [...folha];
    folha = [];

    //termina impressao diagnostica

    if (this.laudo.impressaoDiagnosticaMamaDireita1)
      folha.push(`Cisto mamário.`);
    if (this.laudo.impressaoDiagnosticaMamaDireita2)
      folha.push(`Cistos mamários.`);
    if (this.laudo.impressaoDiagnosticaMamaDireita3)
      folha.push(`Cistos Microcistos agrupados.`);
    if (this.laudo.impressaoDiagnosticaMamaDireita4)
      folha.push(`Ectasia ductal.`);
    if (this.laudo.impressaoDiagnosticaMamaDireita5)
      folha.push(`Linfonodo intramamário.`);
    if (this.laudo.impressaoDiagnosticaMamaDireita6)
      folha.push(`Nódulo sólido mamário.`);
    if (this.laudo.impressaoDiagnosticaMamaDireita7)
      folha.push(`Nódulos sólidos mamários.`);
    if (folha.length > 0) folha.splice(0, 0, 'Mama direita:');
    this.mamaDireitaImpressao = [...folha];
    folha = [];

    if (this.laudo.impressaoDiagnosticaMamaEsquerda1)
      folha.push(`Cisto mamário.`);
    if (this.laudo.impressaoDiagnosticaMamaEsquerda2)
      folha.push(`Cistos mamários.`);
    if (this.laudo.impressaoDiagnosticaMamaEsquerda3)
      folha.push(`Cistos Microcistos agrupados.`);
    if (this.laudo.impressaoDiagnosticaMamaEsquerda4)
      folha.push(`Ectasia ductal.`);
    if (this.laudo.impressaoDiagnosticaMamaEsquerda5)
      folha.push(`Linfonodo intramamário.`);
    if (this.laudo.impressaoDiagnosticaMamaEsquerda6)
      folha.push(`Nódulo sólido mamário.`);
    if (this.laudo.impressaoDiagnosticaMamaEsquerda7)
      folha.push(`Nódulos sólidos mamários.`);
    if (folha.length > 0) folha.splice(0, 0, 'Mama Esquerda:');
    this.mamaEsquerdaImpressao = [...folha];
    folha = [];

    if (this.laudo.comentariosAdcionaisFinal1)
      folha.push(`${this.laudo.comentariosAdcionaisFinal1}`);
    if (this.laudo.categoria1)
      folha.push(`Categoria ${this.laudo.categoria1} pelo sistema de padronização BI-RADS
        ultrassonográfico.`);
    if (this.laudo.comentariosAdcionaisFinal2)
      folha.push(` ${this.laudo.comentariosAdcionaisFinal2}`);
    if (folha.length > 0) folha.splice(0, 0, '');
    this.comentarios = [...folha];
    folha = [];

    if (this.laudo.axilaDireitaLivre2) folha.push(`Axila direita livre.`);
    if (this.laudo.axilaDireitaLivre3) folha.push(`Axila esquerda livre.`);
    if (this.laudo.axilaDireitaLivre4) folha.push(`Axilas livres.`);
    if (this.laudo.axilaDireitaLivre5)
      folha.push(`Linfonodos de aspecto ecográfico habitual nas axilas.`);
    if (this.laudo.axilaDireitaLivre6)
      folha.push(`Linfonodos de aspecto habitual na axila direita.`);
    if (this.laudo.axilaDireitaLivre7)
      folha.push(`Linfonodos de aspecto habitual na axila esquerda.`);
    if (this.laudo.axilaDireitaLivre8)
      folha.push(`Linfonodo de aspecto habitual nas axilas.`);
    if (this.laudo.axilaDireitaLivre9)
      folha.push(`Linfonodo de aspecto habitual na axila direita.`);
    if (this.laudo.axilaDireitaLivre10)
      folha.push(`Linfonodo de aspecto habitual na axila esquerda.`);
    if (this.laudo.axilaDireitaLivre11)
      folha.push(`Linfonodos de aspecto suspeito nas axilas.`);
    if (this.laudo.axilaDireitaLivre12)
      folha.push(`Linfonodos de aspecto suspeito na axila direita.`);
    if (this.laudo.axilaDireitaLivre13)
      folha.push(`Linfonodos de aspecto suspeito na axila esquerda.`);
      if (this.laudo.axilaDireitaLivreN14)
      folha.push(`Mama axilar acessória à direita.`);
      if (this.laudo.axilaDireitaLivreN15)
      folha.push(`Mama axilar acessória à esquerda.`);
      if (this.laudo.axilaDireitaLivreN16)
      folha.push(`Mama axilar acessória bilateral.`);
      if (this.laudo.axilaDireitaLivreN17)
      folha.push(`Mama axilar acessória lipossubstituída à direita.`);
      if (this.laudo.axilaDireitaLivreN18)
      folha.push(`Mama axilar acessória lipossubstituída à esquerda.`);
      if (this.laudo.axilaDireitaLivreN19)
      folha.push(`Mama axilar acessória lipossubstituída.`);

    if (folha.length > 0) folha.splice(0, 0, 'Axilas');
    this.axilaDireitaDois = [...folha];
    folha = [];

    const todos = [
      ...this.inicio,
      ...this.mamaDireita,
      ...this.mamaEsquerda,
      ...this.axilaDireita,
      ...this.axilaEsquerda,
      ...this.impressaoDiagnostica,
      ...this.mamaDireitaImpressao,
      ...this.mamaEsquerdaImpressao,
      
      ...this.comentarios,
      ...this.axilaDireitaDois,
      ...this.conclusao,
    ];
    let tam = 30

    while (todos.length > 0) {
      this.tamanhoElemento.push(todos.splice(0, tam));
      tam = 40
    }
  },

  data() {
    return {
      mamaEsquerda: [],
      mamaDireita: [],
      via: '',
      numText: ['zero', 'um', 'dois', 'três', 'quatro', 'cinco', 'seis'],
      axilaDireita: [],
      axilaEsquerda: [],
      impressaoDiagnostica: [],
      comentarios: [],
      placenta: [],
      cordaoUmbilical: [],
      conclusao: [],
      axilaDireitaDois: [],
      tamanhoElemento: [],
      titulos: [
        'Axilas',
        'Mama Direita',
        'Mama Esquerda',
        'Axila Direita',
        'Mama direita:',
        'Axila Esquerda',
        'Prótese esquerda',
        'Prótese direita',
        'Axila direita:',
        'Impressão Diagnóstica',
        'Mama Esquerda:',
        'Comentários',
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
$default-spacer: 0px;

section {
  text-align: justify;
  margin-left: 10px;
  font-size: 11pt;
  font-family: 'Calibri';

  .header {
    display: flex;
    flex-direction: column;
  }

  h3 {
    margin: $default-spacer * 0;
    padding: 0px;
    line-height: 15px;
  }

  h4 {
    margin: 0px;
    margin-bottom: 5px;
    line-height: 5px;
  }

  p {
    text-align: justify;
    font-family: 'Calibri';
    margin-left: -5px;
    word-wrap: break-word;
    width: 600px;
    margin-top: 5px;
    line-height: 6px;
    padding: 5px;
  }

  br {
    display: block;
    margin-top: 0px;
    margin-left: -5px;
    line-height: 100%;
  }

  span {
    margin: $default-spacer * 0;
    margin-top: 15px;
    margin-left: 0px;
    line-height: 5px;
  }
}
</style>
